<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
  <v-form ref="ticketType">
    <base-material-card icon="mdi-ticket">
      <slot slot="title"><a @click="eventLink()">{{event.name}}</a> / {{ticketType.name}}</slot>
      <slot slot="rightButtons" v-if="ticketType.id">
        <toggle-button text="Edit" openIcon="mdi-pencil" @toggle="showEdit" :showing="showEditToggle"/>
      </slot>
      <div v-if="showEditToggle">
        
        <h2 class="mt-4">Details</h2>

        <text-field label="Name" v-model="editTicketType.name" :rules="[utils.requiredRule]">
          <slot slot="tooltip">The name of the ticket - examples include "Standard Ticket", "Free Ticket", or "Front Row Ticket"</slot> 
        </text-field>
        <text-field label="Description" v-model="editTicketType.description" >
          <slot slot="tooltip">Extra description for ticket type, for example, "Please note front row seats have limited legroom."</slot> 
        </text-field>
        <text-field label="Number Of Tickets" v-model="editTicketType.quantity" :rules="[utils.integerRule]">
          <slot slot="tooltip">The total number of tickets of this type that are avaiable.<br/>You can change this at any time, but it can't be less than the number of tickets sold.</slot> 
        </text-field>

        <text-field label="Max Tickets per Order" v-model="editTicketType.maximumPerOrder" :rules="[utils.integerRule]">
          <slot slot="tooltip">The maximum number of tickets each customer can buy at one time.</slot> 
        </text-field>

        <text-field label="Price" :prefix="getCurrencyPrefix(boxOffice.currency)" v-model="editTicketType.price" :rules="[priceRule]">
          <slot slot="tooltip">The headline price for your tickets.  <br/>You must have connected your Box Office to Stripe to sell tickets. To do this, visit Openstage Manager > Settings > Artist Settings and click on the Payment tabs.</slot> 
        </text-field>

        <text-field label="Booking Fee" :prefix="getCurrencyPrefix(boxOffice.currency)" v-model="editTicketType.bookingFee" :rules="[priceRule]">
          <slot slot="tooltip">
            A bookng fee to be added to each ticket price.</br>You could set this to cover your Openstage fee (25p)</br>
            Or also cover your Stripe fees, or any amount your like.</br> Leave blank to absorb all fees yourself.
          </slot> 
        </text-field>
        <text-field label="Product Code" v-model="editTicketType.productCode">
          <slot slot="tooltip">
            Optional. For selling merchandise where your supplier needs a code.
          </slot>           
        </text-field>
        
        <h2 class="mt-4">Content</h2>
        <div class="columnContainer" style="align-items:stretch;">
          <div class="column column--auto column--highlight">
            <v-label>
              <div class="mt-3 mb-1">Ticket Image
                <tooltip top>
                  Customers can see this when they buy tickets.
                </tooltip>
              </div>
            </v-label>
            <img v-if="image" :src="getCloudinaryImage({url: image, width: 760})">
            <select-image-button :image="image" :boxOffice="boxOffice" @selectImage="selectImage" @deleteImage="deleteImage"/>
          </div>
          <div class="column column--highlight" style="flex-basis:800px;">
            <v-label>
              <div class="mt-3 mb-1">Ticket Content
                <tooltip top>
                  Here you can add a single box of content, or multiple sections of content that expand and retract.
                </tooltip>
              </div>
            </v-label>
            <div class="sections" :key="contentKey">
              <div v-for="(c, i) in content" :key="i" class="sections__item item">
                <v-text-field v-model="c.title" label="Title"/>
                <p>Content</p>
                <div class="editor">
                  <rich-text-editor v-model="c.value" />
                </div>
                <div class="delete"><span @click="deleteContent(i)" class="delete__cta">Delete <v-icon>mdi-trash-can</v-icon></span></div>
              </div>
              <div class="sections__item item item--new">
                <span class="clickable" title="Add more content" @click="addContent()" />
                <v-icon>mdi-plus-circle</v-icon>
              </div>
            </div>
          </div>
        </div>
        
        <h2 class="mt-4">Options</h2>

        <div class="d-flex">
          <v-switch v-model="editTicketType.showAvailability"></v-switch>
          <div class="pt-5" style="valign:middle">Show available tickets at box office? 
          <tooltip left>Would you would like to show customers the number of tickets still available when they are purchasing at the Box Office?</tooltip></div>
        </div>

        <div class="d-flex">
          <v-switch v-model="editTicketType.showTicketNumbers"></v-switch>
          <div class="pt-5" style="valign:middle">Show Tickets Numbers? 
          <tooltip left>If you don't need ticket numbers for this ticket type, you can turn them off so customers won't see them</tooltip></div>
        </div>

        <div :class="{'d-flex': $vuetify.breakpoint.smAndUp}">
          Tickets on sale
          <div class="d-flex">
            <div small class="ml-2 mr-2"> from</div> 
            <datetime input-id="start" class="mb-5" input-style="border-bottom:1px solid" v-model="onSaleFrom" type="datetime" :max-datetime="onSaleTo"></datetime>
          </div> 
          <div class="d-flex">
            <span small class="ml-2 mr-2"> to </span>
            <datetime input-id="end" class="mb-5" input-style="border-bottom:1px solid" v-model="onSaleTo" type="datetime" :min-datetime="onSaleFrom"></datetime>
          </div> 
          <div class="ml-1" style="valign:top"><tooltip left>When should tickets go on sale, and stop being sold?<br/>Leave blank to start selling tickets immediately and indefinitely.</tooltip></div>
        </div>

        <v-row class="mt-5">
          <v-col cols="auto">
            <v-btn color="success" @click="updateTicketType">Save</v-btn>
            <v-btn color="white" @click="showEditToggle = false">Cancel</v-btn>
          </v-col>
          <v-spacer/>
          <v-col cols="auto">
            <v-btn class="mr-0" color="error" @click="deleteTicketType">Delete</v-btn>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="4">Name</v-col>
          <v-col cols="8">{{ticketType.name}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Description</v-col>
          <v-col cols="8">{{ticketType.description}}</v-col>
        </v-row>          
        <v-row>
          <v-col cols="4">Number of Tickets</v-col>
          <v-col cols="8">{{ticketType.quantity}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Max Tickets per Order</v-col>
          <v-col cols="8">{{ticketType.maximumPerOrder}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Price</v-col>
          <v-col cols="8">{{utils.priceDisplay(ticketType.price, boxOffice.currency)}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Booking Fee</v-col>
          <v-col cols="8">{{utils.priceDisplay(ticketType.bookingFee, boxOffice.currency)}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Product Code</v-col>
          <v-col cols="8">{{ticketType.productCode}}</v-col>
        </v-row>          
        <v-row>
          <v-col cols="4">Show Available Tickets?</v-col>
          <v-col cols="8">{{ticketType.showAvailability}}</v-col>
        </v-row>
        <v-row>
          <v-col cols="4">Show Ticket Numbers?</v-col>
          <v-col cols="8">{{ticketType.showTicketNumbers}}</v-col>
        </v-row>
        <v-row v-if="ticketType.onSaleFrom || ticketType.onSaleTo">
          <v-col cols="4">Tickets on sale</v-col>
          <v-col cols="8">from {{utils.dateToShortString(ticketType.onSaleFrom)}} to {{utils.dateToShortString(ticketType.onSaleTo)}}</v-col>
        </v-row>
        <v-row v-if="ticketType.image">
          <v-col cols="4">Ticket Image</v-col>
          <v-col cols="8">
            <img :src="getCloudinaryImage({url: ticketType.image, width: 110})">
          </v-col>
        </v-row>
      </div>
    </base-material-card>
  </v-form>

    <v-form ref="ticket">
      <base-material-card icon="mdi-ticket-confirmation">
        <slot slot="title">Confirmed Tickets</slot>
        <v-row class="mx-n1 underlinedRow">
          <v-col cols="3">Number</v-col>
          <v-col cols="3">Name</v-col>
          <v-col cols="3">Time</v-col>
          <v-col cols="3">Total Price</v-col>
        </v-row>
        <v-row>
          <v-col class="clickableRow">
            <v-row class="mx-n1 underlinedRow" v-for="(ticket, i) in tickets" :key="i" @click="selectItem(ticket)">
              <v-col cols="3">{{ticket.ticketNumber}}</v-col>
              <v-col cols="3">{{ticket.orderName}}</v-col>
              <v-col cols="3">{{utils.dateToShortString(ticket.eventTimeStart)}}</v-col>
              <v-col cols="3">{{utils.priceDisplay(ticket.price + ticket.bookingFee, boxOffice.currency)}}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </base-material-card>
    </v-form>

  </v-container>

</template>
  
<script>
import Api from '@/services/Api.js'; 
import Utils from '@/services/Utils.js';
import Store from '@/services/Store.js';
import { getCloudinaryImage } from '@/helpers/cloudinary'
import { getCurrencyPrefix } from '@/helpers/currency'

  export default {
    name: 'TicketType',

    components: {
      BaseMaterialCard: () => import('@/components/base/MaterialCard'),
      Tooltip: () => import('./components/Tooltip'),
      TextField: () => import('./components/TextField'),
      ToggleButton: () => import('./components/ToggleButton'),
      RichTextEditor: () => import('./components/RichTextEditor2'),
      SelectImageButton: () => import('./components/SelectImageButton'),
    },  

    data () {
      return {
        getCurrencyPrefix,
        getCloudinaryImage,
        ticketType: {},
        eventTime: {},
        event: {},
        boxOffice: {},
        editTicketType: {},
        orders: [],
        eventTimes: [],
        user: {},
        onSaleFrom: null,
        onSaleTo: null,
        tickets: [],
        showCreateOrderToggle: false,
        image: null,
        images: null,
        showEditToggle: false,
        contentKey: 0,
        content: [
          {
            title: '',
            value: ''
          }
        ]
      }
    },

    
    computed: {
      utils: function () {
        return Utils;
      },
    },

    created() {
      this.ticketType.id = this.$route.params.id;
      this.load();
    },
    
    methods: {

      deleteContent (i) {
        this.content.splice(i, 1)
        this.contentKey++
      },

      addContent () {
        this.content.push({title:'',value:''})
        this.contentKey++
      },

      selectImage(image) {
        this.image = image;
        this.editTicketType.image = image;
      },

      deleteImage() {
        this.image = null;
        this.editTicketType.image = null;
      },

      eventLink() {
        this.$router.push("/dashboard/EventDetails/" + this.event.id);
      },

      priceRule(value) {
        return Utils.priceRuleAndCheckStripe(this.boxOffice, value);
      },

      async load() {
        this.ticketType = await Api.post(this, "TicketType", "read", this.ticketType);
        if (this.ticketType.richDescription) {
          this.content = JSON.parse(this.ticketType.richDescription)
        }
        this.event.id = this.ticketType.eventId;
        this.event = await Api.post(this, "Event", "read", this.event);
        this.eventTimes = await Api.post(this, "EventTime", "list", this.event);
        if (this.eventTimes && this.eventTimes.length > 0) {
          this.eventTime = this.eventTimes[0];
        }
        this.boxOffice.id = this.event.boxOfficeId;
        this.boxOffice = await Api.post(this, "BoxOffice", "read", this.boxOffice);
        Store.store(this.boxOffice, this.event);
        this.listTickets();
        this.images = await Api.post(this, "Image", "list", this.boxOffice);
      },

      async listTickets() {
        this.tickets = await Api.post(this, "Ticket", "list", {ticketTypeId: this.ticketType.id, status:"confirmed"});
      },

      async updateTicketType() {
        if (! await Utils.validate(this.$refs.ticketType) ) {
          return;
        }
        this.ticketType = this.editTicketType;
        this.ticketType.price = Utils.priceToNumber(this.editTicketType.price);
        this.ticketType.bookingFee = Utils.priceToNumber(this.editTicketType.bookingFee);
        this.ticketType.onSaleFrom = Utils.dateToDateTime(this.onSaleFrom);
        this.ticketType.onSaleTo = Utils.dateToDateTime(this.onSaleTo);
        this.ticketType.richDescription = JSON.stringify(this.content);
        this.ticketType = await Api.post(this, "TicketType", "update", this.ticketType);
        this.showEdit(false);
      },

      showEdit(show) {
        if (show) {
          this.editTicketType = Utils.jsonCopy(this.ticketType);
          this.editTicketType.price = Utils.priceToString(this.ticketType.price);
          this.editTicketType.bookingFee = Utils.priceToString(this.ticketType.bookingFee);
          this.onSaleFrom = Utils.dateToIso(this.ticketType.onSaleFrom);
          this.onSaleTo = Utils.dateToIso(this.ticketType.onSaleTo);
          this.image = this.ticketType.image;
        } 
        this.showEditToggle = show;
      },

      selectItem(ticket) {
        this.$router.push("/Dashboard/Ticket/" + ticket.id)
      },

      async deleteTicketType() {
        await Api.post(this, "TicketType", "delete", this.ticketType);
        this.$router.push("/Dashboard/EventDetails/" + this.ticketType.eventId)
      },
    }
  }
</script>
